import Vue from 'vue'
import Router from 'vue-router'
import Users from './users'
import AdminApps from './admin_apps'
import Contacts from './contacts'
import CustomersCardsTypes from './customers-cards-types'
import CustomersCards from './customers-cards'
import Invoices from './invoices'
import InvoicesReceived from './invoices-received'
import ProductsAttributes from './productsattributes'
import EshopAttributes from './eshop-attributes'
import ProductsGroups from './productsgroups'
import ProductsCategories from './products-categories'
import Brands from './brands'
import Products from './products'
import Orders from './orders'
import PriceLevels from './price-levels'
import ProductsPricing from './products-pricing'
import Dashboard from '@/components/core/Dashboard'
import WarehousesMovements from './warehouses-movements'
import WarehousesReports from './warehouses-reports'
import Receipts from './receipts'

Vue.use(Router)

export default new Router({
	routes: [{
		path: '/',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			requireAuth: true
		}
	},
		...Users,
		...AdminApps,
		...Contacts,
		...CustomersCardsTypes,
		...CustomersCards,
		...Invoices,
		...InvoicesReceived,
		...ProductsAttributes,
		...ProductsGroups,
		...ProductsCategories,
		...Brands,
		...Products,
		...Orders,
		...PriceLevels,
		...WarehousesMovements,
		...WarehousesReports,
		...ProductsPricing,
		...EshopAttributes,
		...Receipts
	]
})
