<template>
	<v-dialog :value="visible" max-width="800">
		<v-card>
			<iframe style="width:100%;height:500px;border:0;" :src="src"></iframe>
			<v-card-actions><v-spacer></v-spacer><v-btn @click.native="close" text>Zavrieť</v-btn></v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
	import { mapState } from 'vuex'

	export default {
		computed: {
			...mapState('core/wPrintDialog', [ 'visible', 'src' ])
		},
		methods: {
			close () {
				this.$store.commit('core/wPrintDialog/visibility', false)
				this.$nextTick(() => {
					this.$store.commit('core/wPrintDialog/cleanSrc')
				})
			}
		}
	}
</script>
