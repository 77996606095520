import loginAPI from '@/api/login'

const state = {
	logged: false,
	user: {},
	onLogin: []
}

const mutations = {
	login (state, p) {
		state.logged = true
		state.user = p
		if (state.onLogin.length > 0) {
			for (let i = 0, l = state.onLogin.length; i < l; i++) {
				let f = state.onLogin.shift()
				f()
			}
		}
	},
	logout (state) {
		state.logged = false
		state.user = {}
	},
	setOnLoginTrigger (state, f) {
		state.onLogin.push(f)
	}
}

const actions = {
	login ({ dispatch, commit }, p) {
		dispatch('core/desktop/init', null, { root: true })
		commit('login', p)
	},
	logout ({ dispatch, commit }) {
		return loginAPI.logout().then((res) => {
			commit('core/desktop/terminate', null, { root: true })
			commit('logout')
		}).catch((error) => {
			commit('core/snackbar/show', { text: error.message }, { root: true })
		})
	},
	checkLogin ({ dispatch, commit }) {
		return loginAPI.checkLogin().then((res) => {
			dispatch('login', res)

			setTimeout(() => {
				commit('core/desktop/loginChecked', null, { root: true })
			}, 200)

			return res
		}).catch(() => {
			commit('core/desktop/loginChecked', null, { root: true })
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
