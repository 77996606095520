<template>
	<v-app>
	<Login/>
	<Snackbar/>
	<wPrintDialog/>
	<template v-if="active">
		<AppToolbar v-show="showToolbar"/>
		<AppNavigationDrawer/>
		<v-main>
			<router-view/>
		</v-main>
	</template>
	<template v-else>
	Loader!!!
	</template>
	</v-app>
</template>

<script>
import AppToolbar from '@/components/core/appToolbar'
import Login from '@/components/core/Login'
import Snackbar from '@/components/core/Snackbar'
import AppNavigationDrawer from '@/components/core/appNavigationDrawer'
import { mapGetters, mapState } from 'vuex'

export default {
	components: { AppToolbar, AppNavigationDrawer, Snackbar, Login },
	name: 'App',
	computed: {
		...mapGetters('core/desktop', [ 'active' ]),
		...mapState('core/desktop', [ 'showToolbar' ])
	},
	mounted () {
		this.$nextTick(() => {
			this.$store.commit('core/desktop/appMounted')
		})
	}
}
</script>
