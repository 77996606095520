import iAjax from 'iajax'

export default {
	login (credentials) {
		return new Promise((resolve, reject) => {
			iAjax.request({
				url: '/auth/login',
				method: 'post',
				data: JSON.stringify(credentials),
				headers: { 'Content-type': 'application/json' },
				success (data) {
					resolve(data)
				},
				error (etype, e, xhr, data) {
					reject(data)
				}
			}).send()
		})
	},
	// make logout
	logout (success, error) {
		return new Promise((resolve, reject) => {
			iAjax.get('/auth/logout', (res) => {
				resolve()
			}, () => {
				throw Error('Chyba pri odhlasovaní.')
			})
		})
	},
	checkLogin () {
		return new Promise((resolve, reject) => {
			iAjax.get('/auth/login', (res) => {
				resolve(res)
			}, () => {
				reject(Error('Chyba loginu'))
			})
		})
	},

	// recovery
	recoveryRequest (credentials) {
		return new Promise((resolve, reject) => {
			iAjax.form(credentials, {
				url: '/users/recovery',
				success (data) {
					if (data['success'] === true) resolve(data)
					else reject(data)
				},
				error (type, frm, e, xhr, data) {
					reject(data)
				}
			}).submit()
		})
	},
	checkRecoveryToken (token) {
		return new Promise((resolve, reject) => {
			iAjax.get('/users/checkrecoverytoken', { token }, (res) => {
				resolve(res)
			}, () => {
				throw Error('Chyba pri odhlasovaní.')
			})
		})
	},
	sendRecoveryData (credentials) {
		return new Promise((resolve, reject) => {
			iAjax.form(credentials, {
				url: '/users/makerecovery',
				success (data) {
					if (data['success'] === true) resolve(data)
					else reject(data)
				},
				error () {
					throw Error('Chyba pri komunikácii so serverom. Skúste neskôr.')
				}
			}).submit()
		})
	}
}
