<template>
<v-container fill-height fluid>
	<v-layout align-center justify-center>
		<v-img class="dashboardLogo" :aspect-ratio="227/150" max-width="50%" src="/static/logo.svg"/>
	</v-layout>
</v-container>
</template>
<script>
export default {
	mounted () {
		this.$store.commit('core/appToolbar/resetToolbar')
		this.$store.commit('core/appToolbar/setHeader', 'Dashboard - Floy')
	}
}
</script>
<style>
.dashboardLogo {
	filter: grayscale(80%);
	opacity: 0.15;
}
</style>
