const defaultSettings = {
	src: 'about:blank',
	visible: false
}

const state = Object.assign({}, defaultSettings)

const mutations = {
	show (state, src) {
		state.src = src
		state.visible = true
	},
	cleanSrc (state) {
		state.src = 'about:blank'
	},
	visibility (state, p) {
		state.visible = p
	}
}

export default {
	namespaced: true,
	state,
	mutations
}
