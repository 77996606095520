<template>
<transition name="slideDown">
<div class="loginWindow" v-if="show">
<div class="loginFormBox">
<div class="loginImage"><img src="/static/logo.svg" alt="Floy"></div>
<div v-show="passwordRecoveryMode" class="text-center"><p class="title pt-4">Obnovenie hesla</p>Zadajte email, pod ktorým ste zaregistrovaný.</div>
<v-form v-model="formConfig.valid">
<v-text-field v-bind="formFields.email" v-model="formFields.email.value" :label="$t('Email')" @keyup.enter="$refs.pwd.focus()"></v-text-field>
<v-text-field v-show="!passwordRecoveryMode" v-bind="formFields.password" :label="$t('Password')" @keyup.enter="invokeLogin" ref="pwd" v-model="formFields.password.value" :type="showPassword ? 'password':'text'" :append-icon="showPassword ? 'mdi-eye':'mdi-eye-off'" @click:append="() => (showPassword = !showPassword)"></v-text-field>
<div class="text-center pb-2"><v-btn text color="primary" :loading="formLoading" @click="invokeLogin" :disabled="!formConfig.valid">{{ !passwordRecoveryMode ? 'Sign In':'Submit' | translate }}</v-btn></div>
<div class="text-center"><a href="#" style="text-decoration:none" @click="passwordRecoveryMode = !passwordRecoveryMode">{{ !passwordRecoveryMode ? 'Obnovenie hesla':'&laquo; Späť' }}</a></div>
</v-form>
</div>
</div>
</transition>
</template>

<script>
import { mapState } from 'vuex'
import formMixin from '@/mixins/formMixin'
import loginAPI from '@/api/login'
import wSlideDown from '@/helpers/wSlideDown.vue'

let frmMixin = formMixin({
	fields: [{
		name: 'email',
		/* rules: [
			(v) => !!v || 'Email je povinný',
			(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email nemá správny formát'
		], */
		required: true
	}, {
		name: 'password',
		// rules: [ (v) => !!v || 'Heslo je povinné' ],
		required: true
	}],
	config: {
		api: loginAPI,
		apiMethods: {
			submit: 'login'
		}
	}
})

export default {
	name: 'login',
	mixins: [ frmMixin ],
	components: {
		wSlideDown
	},
	data () 	{
		return {
			showPassword: true,
			passwordRecoveryMode: false
		}
	},
	computed: mapState('core/auth', {
		show (state) {
			if (this.$route.matched.length === 0) return false
			return !state.logged
		}
	}),
	watch: {
		passwordRecoveryMode (v) {
			this.formFields.password.value = !v ? '' : 'a'
			this.formConfig.apiMethods['submit'] = !v ? 'login' : 'recoveryRequest'
		}
	},
	methods: {
		invokeLogin () {
			this.formSubmit().then((res) => {
				if (this.passwordRecoveryMode === true) {
					this.passwordRecoveryMode = false
				} else this.$store.dispatch('core/auth/login', res)
			}).catch(() => { })
		}
	},
	mounted () {
		this.$nextTick(() => this.$store.dispatch('core/auth/checkLogin'))
	}
}
</script>

<style lang="scss" scoped>
.loginWindow {
	position: fixed;
	width: 100%;
	height: 100%;
	background: white;
	background: linear-gradient(-45deg, #efefef, #fff);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loginImage {
	padding: 0 50px;
	padding-bottom: 10px;
	text-align: center;

	img {
		max-width: 100%;
		display:inline-block;
	}
}

.slideDown-enter, .slideDown-leave-to {
	transform: translateY(-100%);
}

.slideDown-enter-active, .slideDown-leave-active {
	transition: all 1s ease;
}

.loginFormBox {
	max-width: 90%;
	padding: 20px;
	width: 300px;

	img {
		margin-bottom: 20px;
	}
}
</style>
