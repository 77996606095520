import API from '@/api/states'

const state = function () {
	return {
		data: []
	}
}

const mutations = {
	setData (state, data) {
		state.data = data
	}
}

const actions = {
	load (ctx) {
		return API.loadData().then((data) => {
			ctx.commit('setData', data)

			return data
		}).catch((error) => {
			ctx.commit('core/snackbar/show', { text: error.message }, { root: true })

			throw error
		})
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}
