import Vue from 'vue'
import Vuex from 'vuex'

// core stores
import appToolbar from './core/appToolbar'
import snackbar from './core/snackbar'
import wPrintDialog from './core/wPrintDialog'
import auth from './core/auth'
import desktop from './core/desktop'
import currencies from './core/currencies'
import states from './core/states'
import measureUnits from './core/measure_units'
import vat from './core/vat'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
		core: {
			namespaced: true,
			modules: {
				appToolbar,
				snackbar,
				wPrintDialog,
				auth,
				desktop,
				currencies,
				states,
				measureUnits,
				vat
			}
		}
	},
	strict: debug
})
