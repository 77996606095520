const state = {
	header: '',

	// filter
	filterBtnShow: false,
	settingsBtnShow: false,
	overviewsBtnShow: false,
	filterActive: false,
	filterVisible: false,
	settingsVisible: false,
	overviewsVisible: false,

	// download
	downloadLink: '',

	// top left icon
	menuIcon: 'mdi-menu',
	menuIconCallback: null,

	// nav
	drawerShow: false
}

const getters = {
	downloadBtnVisible (state) {
		return state.downloadLink !== ''
	}
}

const mutations = {
	setHeader (state, h) {
		state.header = h
	},
	setFilterBtnShow (state, b) {
		state.filterBtnShow = b
	},
	setFilterActive (state, b) {
		state.filterActive = b
	},
	setFilterVisible (state, b) {
		state.filterVisible = b
	},
	toggleFilterVisible (state) {
		state.filterVisible = !state.filterVisible
	},
	setSettingsBtnShow (state, b) {
		state.settingsBtnShow = b
	},
	setSettingsVisible (state, b) {
		state.settingsVisible = b
	},
	toggleSettingsVisible (state) {
		state.settingsVisible = !state.settingsVisible
	},
	setOverviewsBtnShow (state, b) {
		state.overviewsBtnShow = b
	},
	setOverviewsVisible (state, b) {
		state.overviewsVisible = b
	},
	toggleOverviewsVisible (state) {
		state.overviewsVisible = !state.overviewsVisible
	},
	setDownloadLink (state, d = '') {
		state.downloadLink = d
	},
	setMenuIcon (state, i) {
		state.menuIcon = i
	},
	setMenuIconCallback (state, c) {
		state.menuIconCallback = c
	},
	toggleMainNav (state, p) {
		state.drawerShow = p
	},
	resetToolbar (state) {
		state.filterBtnShow = false
		state.settingsBtnShow = false
		state.overviewsBtnShow = false
		state.filterActive = false
		state.filterVisible = false
		state.downloadLink = ''
		state.menuIcon = 'mdi-menu'
		state.menuIconCallback = null
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}
