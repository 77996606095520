import iAjax from 'iajax'

export default {
	loadData (credentials = {}) {
		return new Promise((resolve, reject) => {
			iAjax.get('/states', credentials, (data) => {
				resolve(data)
			}, () => {
				throw Error('Chyba pri načítaní štátov.')
			})
		})
	}
}
