const data = {
	// Form validation
	'ValidatorWrongType': 'Položka má nesprávny typ',
	'ValidatorRequired': 'Položka je povinná',
	'ValidatorTextMinVal': 'Minimálne {param0} znakov',
	'ValidatorTextMaxVal': 'Maximálne {param0} znakov',
	'ValidatorNumMinVal': 'Minimálna hodnota je {param0}',
	'ValidatorNumMaxVal': 'Maximálna hodnota je {param0}',
	'ValidatorDateMinVal': 'Dátum môže byť min {param0}',
	'ValidatorDateMaxVal': 'Dátum môže byť max {param0}',
	'ValidatorNumNotNum': 'Musí byť číslo',
	'ValidatorNumIsNegative': 'Nesmie byť negatívna',
	'ValidatorNumIsFloat': 'Musí byť celé číslo',
	'ValidatorNumManyDecimals': 'Max. {param0} desatinné miesta',
	'ValidatorDateNotDate': 'Dátum má nesprávny formát',
	'ValidatorDateMinNotDate': 'Chybný formát min. dátumu',
	'ValidatorDateMaxNotDate': 'Chybný formát max. dátumu',
	'ValidatorEmailNotEmail': 'Email má chybný formát',
	'ValidatorUrlNotUrl': 'Adresa nie je správna',
	'ValidatorRegexpNoMatch': 'Nesprávny formát',
	'ValidatorRegexpBad': 'Chybný regexp',

	// Dialog buttons
	'DlgSave': 'Uložiť',
	'DlgClose': 'Zavrieť',
	'DlgCancel': 'Zrušiť',
	'DlgYes': 'Áno',
	'DlgNo': 'Nie',
	'DlgOk': 'Ok',

	// Action phrases
	'Add': 'Pridať',
	'Edit': 'Upraviť',
	'Delete': 'Vymazať',

	// Login
	'Sign In': 'Prihlásiť',
	'Password Recovery': 'Obnovenie hesla',
	'Submit': 'Odoslať',
	'Wrong email or password': 'Chybný email alebo heslo',

	// Base phrases
	'Email': 'Email',
	'Password': 'Heslo',
	'Type': 'Typ',
	'Title': 'Názov',
	'Search': 'Hľadať',
	'Code': 'Kód',
	'VAT': 'DPH',
	'Labels': 'Štítky',
	'Label': 'Štítok',
	'Selection': '{count} záznam ::: {count} záznamy ::: {count} záznamov',
	'Weight': 'Hmotnosť',
	'Settings': 'Nastavenia',
	'Overviews': 'Prehľady',
	'Numbering': 'Číslovanie',
	'Tags': 'Štítky',
	'Responsible person':'Zodpovedná osoba',
	'Mobile app': 'Mobil',

	// Error messages
	'Wrong request': 'Chybná požiadavka',
	'System failure': 'Došlo k systémovej chybe',
	'The form contains errors': 'Formulár obsahuje chyby',
	'Record could not be found': 'Záznam sa nepodarilo nájsť',
	'Record could not be deleted': 'Záznam nie je možné vymazať',
	'Error storing record': 'Chyba pri ukladaní záznamu',
	'Error deleting record': 'Záznam sa nepodarilo vymazať',

	// Contacts
	'Contacts': 'Kontakty',
	'Create new contact': 'Vytvorte nový kontakt',
	'Name Prefix': 'Pred menom',
	'First Name': 'Meno',
	'Last Name': 'Priezvisko',
	'Name Suffix': 'Za menom',
	'Street': 'Ulica',
	'Number': 'Číslo',
	'ZIP': 'PSČ',
	'Town': 'Mesto',
	'State': 'Štát',
	'ICO': 'IČO',
	'DIC': 'DIČ',
	'icDPH': 'IČ DPH',
	'DPH payer': 'platca DPH',
	'Contact person': 'Kontaktná osoba',
	'Phone': 'Telefón',
	'Bank name': 'Názov banky',
	'Supplier code': 'Kód dodávateľa',
	'Add email': 'Pridať email',
	'Add phone': 'Pridať telefón',
	'Add address': 'Pridať adresu',
	'Add account': 'Pridať účet',
	'Select Address': 'Vyberte adresu',

	// Invoices
	'Invoices': 'Vydané faktúry',
	'Variable Symbol': 'Variabilný symbol',
	'Constant Symbol': 'Konštantný symbol',
	'Specific Symbol': 'Špecifický symbol',
	'Number / VS / SS': 'Číslo / VS / SS',
	'Payment method': 'Spôsob platby',
	'Purchaser': 'Odberateľ',
	'Name and Address of Purchaser': 'Názov a adresa odberateľa',
	'Created date': 'Dátum vystavenia',
	'Due date': 'Dátum splatnosti',
	'Delivery date': 'Dátum dodania',
	'Currency': 'Mena',
	'Item name': 'Názov položky',
	'Description': 'Popis',
	'Unit price': 'Jedn. cena',
	'Year / Month': 'Rok / Mesiac',

	// Objednávky
	'Orders': 'Objednávky',
	'Delivery Address': 'Adresa dodania',
	'Order status': 'Stav objednávky',

	// Received Invoices
	'Received Invoices': 'Prijaté faktúry',
	'Name and Address of Supplier': 'Názov a adresa dodávateľa',

	// Products attributes
	'Products attributes': 'Produktové atribúty',

	// Products groups
	'Products groups': 'Produktové skupiny',
	'Products group': 'Produktová skupina',
	'Product name template': 'Šablóna názvu produktu',
	'Code generate attribute': 'Atribút pre generovanie kodu',

	// Brands
	'Brands': 'Značky',
	'Brand': 'Značka',

	// Users
	'Users': 'Používatelia',
	'Rights': 'Práva',

	// Customer cards
	'Customer': 'Zákazník',
	'Customers Cards Types': 'Šablóny kariet',
	'Customers Cards': 'Vernostné karty',
	'Card Type': 'Typ karty',
	'Credit coefficient': 'Koeficient prepočtu kreditu',
	'For one use': 'Na jedno použitie',

	// Product
	'Products': 'Produkty',
	'Products cards': 'Produktové karty',
	'Product type': 'Typ produktu',
	'Basic title': 'Základný názov',
	'Cash register title': 'Názov pre pokladňu',
	'Generated title': 'Generovaný názov',
	'Product code': 'Kód produktu',
	'Product supplier code': 'Dodávateľský kód produktu',
	'Recommended sell price': 'Odporúčaná predajná cena',
	'Last purchase price': 'Posledná nákupná cena',
	'Average price': 'Priemerná cena',
	'Supplier': 'Dodávateľ',
	'New item add by pressing ENTER': 'Novú položku pridáte stlačením klávesy ENTER',
	'Entered EAN is already occupied': 'Zadaný EAN je už obsadený',
	'Basic': 'Základné',
	'Images': 'Obrázky',
	'Attributes': 'Atribúty',
	'Warehouse': 'Sklad',
	'Create new product': 'Vytvorte nový produkt',
	'Pricing': 'Cenotvorba',
	'Initial stock quantity': 'Počiatočný stav na sklade',
	'Products categories': 'Produktové kategórie',
	'Products category': 'Produktová kategória',

	// Warehouse
	'Warehouse movements': 'Skladové pohyby',
	'Movement kind': 'Druh pohybu',
	'Stock - receipts, expense': 'Sklad - príjem, výdaj',
	'Receipt': 'Príjem',
	'Expense': 'Výdaj',
	'Document number': 'Číslo dokladu',
	'Note': 'Poznámka',
	'Importer': 'Dovozca',
	'Search ...': 'Vyhľadať ...',

	// Receipts
	'Receipts': 'Pokladničné bločky'
}

export default {
	data,
	locale: 'sk'
}
