import API from '@/api/currencies'

const state = function () {
	return {
		data: []
	}
}

const mutations = {
	setData (state, data) {
		state.data = data
	}
}

const getters = {
	currenciesRes: (state) => state.data.map((v) => { return { id: v.id, name: v.name + ' (' + v.sign + ')' } })
}

const actions = {
	load (ctx) {
		return API.loadData().then((data) => {
			ctx.commit('setData', data)

			return data
		}).catch((error) => {
			ctx.commit('core/snackbar/show', { text: error.message }, { root: true })

			throw error
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
