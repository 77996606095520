export default (cfg) => {
	return {
		data () {
			let d = {
				formConfig: {
					valid: false,
					primaryKey: 'id',
					apiMethods: {
						read: 'loadItem',
						submit: 'saveItem'
					},
					...cfg.config
				},
				formFields: {},
				formReferences: {},
				formLoading: false,
				formSubmitting: false
			}

			for (let i = 0, l = cfg.fields.length; i < l; i++) {
				if (typeof cfg.fields[i] === 'string') {
					d.formFields[cfg.fields[i]] = { value: undefined }
				} else {
					d.formFields[cfg.fields[i].name] = { defaultValue: cfg.fields[i].value, ...cfg.fields[i] }
				}
			}

			return d
		},
		methods: {
			formLoad (params) {
				this.formLoading = true

				if (!this.formConfig.api) throw Error('Form api not set')

				return this.formConfig.api[this.formConfig.apiMethods.read](params).then((res) => {
					if (res && res instanceof Object && res.constructor === Object) {
						if (this.formLoadDataProcess) res['data'] = this.formLoadDataProcess(res['data'])
						for (let i in res) if (this.formFields[i]) this.$set(this.formFields[i], 'value', res[i])
						return res
					} else {
						if (res) {
							this.$store.commit('core/snackbar/show', { text: 'Data majú chybný formát' }, { root: true })
						}
					}
				}).catch((error) => {
					this.$store.commit('core/snackbar/show', { text: error.message }, { root: true })
				}).finally(() => {
					this.formLoading = false
				})
			},
			formErrorsSet (opt) {
				for (let i in opt) {
					let tparams = [ opt[i].shift() ]

					if (opt[i].length > 0) {
						let p = {}
						for (let n in opt[i]) p['param' + n] = opt[i][n]
						tparams.push(p)
					}

					this.$set(this.formFields[i], 'errorMessages', [ this.$i18n.translate.apply(null, tparams) ])
				}
			},
			formErrorsClear () {
				for (let i in this.formFields) {
					this.$set(this.formFields[i], 'errorMessages', [])
				}
			},
			getFormValues () {
				let v = {}
				for (let i in this.formFields) {
					if (!this.formFields[i].value) continue
					v[i] = this.formFields[i].value
				}
				return { ...v }
			},
			formSubmit () {
				this.formSubmitting = true
				let frmData = this.getFormValues()
				if (this.formSubmitDataProcess) frmData = this.formSubmitDataProcess(frmData)

				return this.formConfig.api[this.formConfig.apiMethods.submit](frmData).then((res) => {
					if (res['message']) {
						this.$store.commit('core/snackbar/show', { text: res['message'] }, { root: true })
					}

					return res
				}).catch((res) => {
					if (res['message']) {
						this.$store.commit('core/snackbar/show', { text: res['message'] }, { root: true })
					}

					this.formErrorsClear()
					if (res['formErrors']) {
						this.formErrorsSet(res['formErrors'])
					}

					throw res
				}).finally(() => {
					this.formSubmitting = false
				})
			},
			formClear () {
				this.formErrorsClear()
				if (this.$refs.frm) this.$refs.frm.reset()

				// set form default values
				for (let i in this.formFields) {
					if (typeof this.formFields[i].defaultValue === 'undefined' || (!this.formFields[i].defaultValue && this.formFields[i].defaultValue !== 0)) continue
					this.$set(this.formFields[i], 'value', this.formFields[i]['defaultValue'])
				}
			},
			...cfg.methods
		},
		mounted () {
			// CHANGE !!!
			this.$nextTick(() => {
				if (this.formConfig.loadUrl) {
					this.formLoad(this.formConfig.loadUrl, this.formConfig.loadParams || {}, this.formLoadSuccess, this.formLoadError)
				}
			})
		}
	}
}
