import Vue from 'vue'

const defaultSettings = {
	visible: false,
	vpos: 'bottom',
	hpos: null,
	color: '-',
	mode: '',
	timeout: 6000,
	text: ''
}

const state = Object.assign({}, defaultSettings)

const mutations = {
	show (state, p) {
		for (let i in p) {
			state[i] = p[i]
			Vue.set(state, i, p[i])
		}
		state.visible = true
	},
	visibility (state, p) {
		state.visible = p
	}
}

export default {
	namespaced: true,
	state,
	mutations
}
