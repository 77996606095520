<template>
	<v-app-bar app clipped-left class="blue-grey darken-2" dark>
		<v-app-bar-nav-icon @click.stop="iconAction"><v-icon>{{ menuIcon }}</v-icon></v-app-bar-nav-icon>
		<v-toolbar-title>{{ header }}</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-btn text :outlined="filterActive" @click.stop="toggleFilter" v-show="filterBtnShow"><v-icon>mdi-filter-variant</v-icon>&nbsp; Filtrovať</v-btn>
		<v-btn icon @click.stop="toggleOverviews" v-show="overviewsBtnShow"><v-icon>mdi-chart-bar</v-icon></v-btn>
		<v-btn icon :href="downloadLink" v-show="downloadBtnVisible" target="_blank"><v-icon>mdi-cloud-download</v-icon></v-btn>
		<v-btn icon @click.stop="toggleSettings" v-show="settingsBtnShow"><v-icon>mdi-cog</v-icon></v-btn>

		<v-menu offset-x right offset-y bottom :nudge-width="200" v-model="userMenu">
			<template v-slot:activator="{ on }"><v-btn icon dark v-on="on"><v-icon>mdi-account</v-icon></v-btn></template>
			
			<v-card>
				<v-list>
					<v-list-item>
						<v-list-item-avatar>
							<v-icon large>mdi-account</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>{{ user.name }}</v-list-item-title>
							<v-list-item-subtitle>{{ user.company_name }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn text @click="settingsDialog = true">Nastavenia</v-btn>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="logout">Odhlásiť</v-btn>
				</v-card-actions>
			</v-card>
		</v-menu>

	</v-app-bar>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'AppToolbar',
	computed: {
		...mapState('core/appToolbar', [ 'header', 'filterVisible', 'filterBtnShow', 'settingsBtnShow', 'overviewsBtnShow', 'downloadLink', 'menuIcon', 'menuIconCallback', 'filterActive' ]),
		...mapGetters('core/appToolbar', [ 'downloadBtnVisible' ]),
		...mapState('core/auth', [ 'user' ])
	},
	methods: {
		iconAction () {
			if (this.menuIconCallback) this.menuIconCallback()
			else {
				this.$store.commit('core/appToolbar/toggleMainNav', true)
			}
		},
		logout () {
			this.$store.dispatch('core/auth/logout')
		},
		toggleFilter () {
			this.$store.commit('core/appToolbar/toggleFilterVisible')
		},
		toggleSettings () {
			this.$store.commit('core/appToolbar/toggleSettingsVisible')
		},
		toggleOverviews () {
			this.$store.commit('core/appToolbar/toggleOverviewsVisible')
		}
	},
	data () {
		return {
			userMenu: false,
			clipped: true,
			settingsDialog: false
		}
	}
}
</script>
