<template>
	<v-combobox v-bind="{ value, label, loading, items, itemText, itemValue, clearable }" @input="input" chips multiple return-object>
		<template v-slot:selection="{ attrs, item, select, selected }">
			<v-chip v-bind="attrs" :input-value="selected" @click="select" close @click:close="remove(item)">{{ item }}</v-chip>
		</template>
	</v-combobox>
</template>
<script>
// there is bug in vuetify with chips values....
export default {
	props: {
		value: null,
		label: undefined,
		loading: false,
		items: [],
		itemText: {
			type: String,
			default:'text'
		},
		itemValue: {
			type: String,
			default: 'id'
		},
		clearable: false
	},
	computed: {
		currentSelectedRow () {
			if (this.value === null) return {}
			return this.items.reduce((a, v) => v.id === this.value ? v : a, {})
		}
	},
	watch: {
		value (v) {
			this.$emit('data', this.currentSelectedRow)
		}
	},
	methods: {
		input (v) {
			this.$emit('input', v)
		},
		remove (itm) {
			console.log(itm)
			this.input(this.value.filter( v => v != itm[this.itemValue] ))
		}
	}
}
</script>
