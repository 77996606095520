<script>
export default {
	functional: true,
	render: function (createElement, context) {
		let data = {
			height: 0,
			props: {
				name: 'wSlideDown'
			},
			on: {
				enter (el) {
					el.style.overflow = 'hidden'
					el.style.display = 'block'
					el.style.height = 0

					setTimeout(() => { el.style.height = `${el.scrollHeight}px` }, 100)
				},
				leave (el) {
					el.style.overflow = 'hidden'
					el.style.height = `${el.offsetHeight}px`

					setTimeout(() => { el.style.height = 0 }, 100)
				},
				afterEnter (el) {
					el.style.overflow = null
					el.style.height = null
				}
			}
		}

		return createElement('transition', data, context.children)
	}
}
</script>
<style lang="scss" scoped>
wSlideDown {
	overflow: hidden;
}

.wSlideDown-enter-active, .wSlideDown-leave-active {
	transition: all .3s cubic-bezier(.25,.8,.50,1);
}
</style>
