import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Formatters from './plugins/formatters'
import vuetify from './plugins/vuetify'
import iAjax from 'iajax'
import vuexI18n from 'vuex-i18n'
import phrases from './i18n/core_sk'
import './components/base/_globals'

Vue.use(Formatters, store)
Vue.use(vuexI18n.plugin, store)

Vue.config.productionTip = false

new Vue({
	vuetify,
	router,
	store,
	created () {
		this.$store.dispatch('core/currencies/load')
		this.$store.dispatch('core/states/load')
		this.$store.dispatch('core/measureUnits/load')
		this.$store.dispatch('core/vat/load')
	},
	mounted () {
		var _t = this

		// logout trigger
		iAjax.request('prototype', 'beforeProcessRequest', function (xhr) {
			if (xhr.status === 401 && xhr.responseURL.indexOf('/auth/login') === -1) {
				_t.$store.commit('core/auth/setOnLoginTrigger', () => { this.send() })
				_t.$store.commit('core/auth/logout')
				return false
			}
			return true
		})

		// set default language
		this.$i18n.add(phrases.locale, phrases.data)
		this.$i18n.set(phrases.locale)
	},
	render: h => h(App)
}).$mount('#app')
